<template>
  <div class="container margin">
    <div class="video">
      <div class="video__row flex flex__space-between">
        <div class="video__box position">
          <div class="video__title">{{courserData.name}} {{courserData.teacherName ? '(讲师: ' + courserData.teacherName + ')' : ''}}</div>
          <div id="player" v-if="courserData.type === 'INNER'"></div>
          <div class="video__play" v-if="courserData.type === 'OUTER'" @click="toOuter">
            <a-icon type="play-circle" style="font-size:40px;color:#fff;" />
          </div>
          <div class="watermark" v-if="courserData.type === 'INNER'">
            <div class="watermark__item watermark__item_name">{{userName}}</div>
            <div class="watermark__item watermark__item_number">{{workNo}}</div>
          </div>
          <!-- <iframe
            v-else-if="courserData.type === 'OUTER'"
            :src="courserData.videoSource"
            seamless
            frameborder="0"
            width="940px"
            height="547px"
            scrolling="no"
            sandbox="allow-scripts allow-same-origin allow-popups"
          ></iframe> -->
        </div>
        <!-- <task :list="learnData.learningTaskLevelProgressList" /> -->
        <course :data="courseList" @selectCourse="selectCourse" :passFlag="passFlag" :title="title" />
      </div>
      <a-modal
        title="验证"
        :visible="visible"
        :closable="false"
        :keyboard="false"
        :maskClosable="false"
        okText="确认"
        cancelText="取消"
        :confirm-loading="confirmLoading"
        :cancel-button-props="{ props: { disabled: true } }"
        @ok="handleOk"
      >
        <p class="weight__border">
          剩余时间:
          <span style="color:red;">{{popTimeText}}</span>
        </p>
        <p class="weight__border">{{question}}</p>
        <p class="flex flex__align-center">
          你的答案是:
          <a-input
            style="width: 200px;"
            placeholder="请输入"
            v-model="myAnswer"
            @pressEnter="pressEnter"
          />
        </p>
      </a-modal>
      <div class="video__tab">
        <a-tabs default-active-key="1" size="large" type="card" @change="onChange">
          <a-tab-pane key="1" tab="课程简介">
            <div class="video__tab-content">{{courserData.description}}</div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="课程评论">
            <div class="video__tab-content">
              <div class="video__question" v-if="topData">
                <div class="flex">
                  <div class="video__question-tag">【置顶】</div>
                  <div class="video__question-user video__tab_color">{{topData.userName}}：</div>
                  <div class="video__question-text video__tab_color">{{topData.content}}</div>
                </div>
                <div class="video__question-reply flex">
                  <div class="video__question-to video__answers-to">回复</div>
                  <div class="video__question-touser video__tab_color">{{topData.userName}}：</div>
                  <div class="video__question-text video__tab_color">{{topData.reply}}</div>
                </div>
              </div>
              <div class="video__answers">
                <div class="video__answers-item" v-for="(item, index) in commitData" :key="index">
                  <div class="flex">
                    <div
                      class="video__answers-user video__tab_color"
                    >{{item.userName ? item.userName : '用户名'}}：</div>
                    <div class="video__answers-text video__tab_color">{{item.content}}</div>
                  </div>
                  <div class="video__answers-reply flex" v-if="item.reply">
                    <div class="video__answers-to">回复</div>
                    <div
                      class="video__answers-touser video__tab_color"
                    >{{item.userName ? item.userName : '用户名'}}：</div>
                    <div class="video__answers-text video__tab_color">{{item.reply}}</div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="3" :tab="tab">
            <div class="video__tab-content">
              <div class="video__files flex" v-for="(item, index) in pdfData" :key="index">
                <div class="video__files-text">【下载】</div>
                <a
                  class="video__files-name"
                  @click="downFun(item)"
                >{{item.name}}{{ item.fileUrl.slice(item.fileUrl.lastIndexOf(".")) }}</a>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="video__input" v-show="activeKey === '2'">
        <a-input-search
          placeholder="说点什么?"
          enter-button="评论"
          v-model="commit"
          size="large"
          :maxLength="100"
          :loading="loading"
          @search="commitFun"
        />
      </div>
    </div>
  </div>
</template>

<script>
// function isNull(str) {
//   if (str == "") return true;
//   var regu = "^[ ]+$";
//   var re = new RegExp(regu);
//   return re.test(str);
// }

// import task from "@/components/task-list"
import course from "./components/course-list";
import {
  getVideoPlayerSafe,
  getCourseList,
  getCourse,
  setComment,
  getComment,
  getPdfData,
  setCourseRecord
} from "@/api/learning";
// import { getTaskData } from '@/api'
// import { downloadFile } from "@/utils/tools.js";
// import { staticDomainURL } from "@/config";
import Vue from "vue";
import { apiBaseUrl } from "@/config";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { USER_NAME, WORK_NO,USER_ID } from "@/store/mutation-types.js";
export default {
  components: { course },
  data() {
    return {
      title: '学习园地',
      visible: false,
      confirmLoading: false,
      activeKey: "1",
      question: "",
      answer: "",
      myAnswer: "",
      loading: false,
      id: "",
      subTaskId: "",
      commit: "",
      taskPathId: Vue.ls.get("TASK_PATH_ID"),
      userName: Vue.ls.get(USER_NAME),
      workNo: Vue.ls.get(WORK_NO),
      userId: Vue.ls.get(USER_ID),
      player: null,
      recordId: null,
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: "",
      videoData: {},
      courserData: {},
      courseList: [],
      commitData: [],
      topData: {},
      tab: "课程资料",
      pdfData: [],
      timer: null,
      isProgress: false,
      popTime: null,
      popTimeText: "00:00",
      isClick: false,
      passFlag: false
    };
  },
  created() {
    // clearInterval(this.timer);
    // this.timer = null;
    console.log(this.$route.query, "传过来的参数");
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.type = this.$route.query.type;
      this.getData();
    }
  },
  methods: {
    // 获取课程列表
    getData() {
      getCourseList({
        taskId: this.id
      }).then(res => {
        this.courseList = res.result;
        if (this.courseList.length > 0) {
          this.courseId = this.courseList[0].courseId;
          this.subTaskId = this.courseList[0].subTaskId;
          this.getCourseData();
          this.getComment();
          this.getPdfData();
        } else {
          this.$message.warning("暂未配置课程数据，请联系管理员。");
        }
      });
    },
    // 刷新课程列表
    updateData() {
      getCourseList({
        taskId: this.id
      }).then(res => {
        this.courseList = res.result;
      });
    },
    // 获取课程详情
    getCourseData() {
      getCourse({
        id: this.courseId,
        taskType: this.type,
        subTaskId: this.subTaskId
      }).then(res => {
        this.courserData = res.result;
        console.log(this.courserData, "我是课程详情哦");
        this.popupList = res.result.popupList;
        if (this.courserData.type === "INNER") {
          this.vid = res.result.videoSource;
          this.getVideoData();
        } else if (this.courserData.type === "OUTER") {
          this.isClick = true;
          this.title = '外部课程'
          this.uploadCourse(this.courserData.durationIntValue);
          window.open(this.courserData.videoSource)
        } else if (this.courserData.type === "OFFLINE") {
          this.title = '线下课程'
          this.$message.warning("当前不支持播放线下课程");
          // this.$router.back()
        }
      });
    },
    // 获取视频凭证
    getVideoData() {
      getVideoPlayerSafe({
        vid: this.vid
      }).then(res => {
        this.videoData = res.result;
        this.loadPlayerScript(this.loadPlayer);
      });
    },
    // 获取课程评论
    getComment() {
      getComment({
        courseId: this.courseId
      }).then(res => {
        this.commitData = res.result;
        let id = null
        let rows = this.commitData.filter((item, index) => {
          if(item.topFlag === 1) {
            id = index
            return item
          }
        });
        this.topData = rows[0];
        if(id) this.commitData.splice(id, 1)
      });
    },
    // 获取课程资料
    getPdfData() {
      getPdfData({
        courseId: this.courseId
      }).then(res => {
        if (res.result.length != 0) {
          this.tab = "课程资料" + "(" + res.result.length + ")";
        } else {
          this.tab = "课程资料";
        }
        this.pdfData = res.result;
      });
    },
    // 切换tab
    onChange(value) {
      this.activeKey = value;
    },
    // 选择课程
    selectCourse(data) {
      if (!this.isClick) {
        this.$message.warning("操作频繁，请稍后！");
        return false;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.passFlag = false;
      this.courseId = data.courseId;
      this.subTaskId = data.subTaskId;
      if (this.player) this.player.destroy();
      this.isClick = false;
      this.getCourseData();
      this.getComment();
      this.getPdfData();
    },
    // 添加评论
    commitFun(value) {
      // if (isNull(this.value) === false) {
      //   this.$message.warning("不能添加空评论哦!");
      //   return false;
      // }
      if (value.match(/^[ ]*$/)) {
        this.$message.warning("不能添加空评论哦!");
        return false;
      }
      if (value != "") {
        this.loading = true;
        setComment({
          content: value,
          courseId: this.courseId
        })
          .then(res => {
            this.commit = "";
            this.commitData = res.result;
            let rows = this.commitData.filter(item => item.topFlag === 1);
            this.topData = rows[0];
            this.$message.success("添加成功!");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$message.warning("不能提交空评论哦!");
      }
    },
    // 下载
    downFun(record) {
      // if (this.key !== null) {
      //   this.$message.warning('有一个下载任务在进行中')
      //   return false
      // }
      // this.key = index
      // var url = "/api/datum/download/";
      // downloadFile(url, data.id, data.name, () => {
      //   this.key = null
      // });
      let token = Vue.ls.get(ACCESS_TOKEN);
      var url = apiBaseUrl + "/api/datum/download/" + token + "/" + record.id;
      window.open(url);
    },

    // 弹窗验证答案
    handleOk() {
      if (this.myAnswer === this.answer) {
        if (this.popTime === null && this.popTimeText === "00:00 验证超时") {
          // 如果验证已超时, 则刷新页面
          window.location.reload()();
        }
        clearInterval(this.popTime);
        this.popTime = null;
        this.popTimeText = "00:00";
        this.visible = false;
        this.player.j2s_resumeVideo(); // 恢复播放暂停的视频
        this.myAnswer = "";
        this.$message.success("验证成功!");
      } else {
        this.$message.error("答案错误,请重新输入!");
      }
    },
    pressEnter() {
      this.handleOk();
    },
    // 上传当前课程任务记录
    uploadCourse(time) {
      let params = {
        id: this.recordId,
        contentId: this.courseId,
        contentName: this.courserData.name,
        duration: time,
        subTaskType: "COURSE",
        contentType: this.type,
        subTaskId: this.subTaskId,
        subtaskName: this.courserData.name,
        taskPathId: this.id ? this.taskPathId : null
      };
      if (this.type !== "OUTER_COURSE_TASK") {
        let player = JSON.parse(this.player.j2s_realPlayStatus());
        console.log(player.playduration, "播放时长");
        params.actualDuration = player.playduration;
      }
      setCourseRecord(params).then(res => {
        console.log(res);
        this.recordId = res.result.id;
        if (res.result.passFlag) {
          this.updateData();
          this.courserData.passFlag = true;
        }
      });
    },

    // 监听弹窗时间
    popFun(time) {
      if (this.popupList != null) {
        if (this.popupList.length <= 0) return false;
        this.popupList.forEach(item => {
          if (Math.abs(time - item.start) < 3 && !item.isPop) {
            let time = 300;
            this.popTimeText = "00:00";
            if (!this.isProgress) {
              this.popTime = setInterval(() => {
                time--;
                this.popTimeText = this.setTimes(time);
                if (time <= 0) {
                  this.isProgress = true;
                  clearInterval(this.popTime);
                  this.popTime = null;
                  this.popTimeText = "00:00";
                  this.popTimeText = "00:00 验证超时";
                }
              }, 1000);
            }
            this.player.j2s_pauseVideo(); // 暂停播放
            this.visible = true;
            this.question = item.question;
            this.answer = item.answer;
            item.isPop = true;
          }
        });
      }
    },

    // 播放暂停或者结束要处理的事情
    pauseOrOverFun() {
      // 提交一次记录
      if (this.isProgress) return false;
      var videoTimes = Math.floor(this.player.j2s_getCurrentTime());
      if (Number(videoTimes) > 0) {
        this.uploadCourse(videoTimes);
      }
      // 清楚定时器
      clearInterval(this.timer);
      this.timer = null;
      let total = this.player.j2s_getDuration();
      console.log(videoTimes, "当前的播放时长");
      console.log(total, "视频总时长");
      if (videoTimes === total) this.passFlag = true;
    },

    loadPlayerScript(callback) {
      this.isClick = true;
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    // 处理时间
    setTimes(x) {
      var durationValue = this.$moment.duration(x, "seconds");
      var hours = Math.floor(durationValue.asHours());
      var mins = Math.floor(durationValue.asMinutes()) - hours * 60;
      var seconds = Math.floor(durationValue.asSeconds()) - mins * 60;
      var h = "";
      var m = "";
      var s = "";
      hours < 10 ? (h = "0" + hours) : (h = hours);
      mins < 10 ? (m = "0" + mins) : (m = mins);
      seconds < 10 ? (s = "0" + seconds) : (s = seconds);
      // let time = ''
      console.log(h);
      return m + ":" + s;
    },
    // 去外部课程
    toOuter() {
      window.open(this.courserData.videoSource)
    },
    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#player",
        width: 940,
        height: 547,
        vid: this.vid,
        playsafe: this.videoData.token,
        ts: this.videoData.ts,
        sign: this.videoData.sign,
        allowFullscreen: false, // 禁止全屏
        ban_seek: this.courserData.passFlag ? "off" : "on", // 禁止拖动滚动条
        speed: false,
        autoplay: true,
        show_rate: 1, // 只允许流畅画质
        df: 1,
        code:this.userId,
        showAuto: false
      });
      // 初始化播放器成功时
      this.player.on("s2j_onPlayerInitOver", () => {
        clearInterval(this.timer);
        this.timer = null;
        // 播放器初次播放或者从暂停恢复播放时
        this.player.on("s2j_onVideoPlay", () => {
          // 实时获取播放时长
          let times =
            Math.floor(this.player.j2s_getCurrentTime()) > 0
              ? Math.floor(this.player.j2s_getCurrentTime())
              : 0;
          this.timer = setInterval(() => {
            times += 1;
            // 获取视频当前的播放进度 xxs
            var videoTimes = Math.floor(this.player.j2s_getCurrentTime());
            console.log("times", times);
            if (times != 0 && times % 30 === 0 && !this.isProgress) {
              // 每30秒提交一次记录
              this.uploadCourse(videoTimes);
            }
            if (
              this.popupList != null &&
              !this.isProgress &&
              !this.courserData.passFlag
            ) {
              this.popFun(videoTimes);
            }
          }, 1000);
        });
        // 视频暂停时
        this.player.on("s2j_onVideoPause", () => this.pauseOrOverFun());

        // 视频播放完毕时
        // this.player.on('s2j_onPlayOver', () => this.pauseOrOverFun())
        // 监听拖拽进度条
        this.player.on("s2j_onVideoSeek", () => {
          if (this.courserData.passFlag) {
            clearInterval(this.timer);
            this.timer = null;
          }
        });
      });
    }
  },
  beforeDestroy() {
    this.recordId = null;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.player) {
      this.player.destroy();
    }
  },
  watch: {
    $route() {
      clearInterval(this.popTime);
      this.popTime = null;
      this.popTimeText = "00:00";
      this.recordId = null;
      this.player.destroy();
      clearInterval(this.timer);
      this.timer = null;
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.type = this.$route.query.type;
        this.getData();
      }
    }
  }
};
</script>

<style lang = "scss" scoped>
.video {
  &__box {
    width: 940px;
    background: #000;
  }
  &__title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    /* background: linear-gradient(90deg, #c4a468, #f6bea5); */
    box-sizing: border-box;
    padding-left: 30px;
    /* position: relative; */
    height: 42px;
    background: linear-gradient(90deg, #E10B0B, #EE8544);
    padding-right: 10px;
    border-radius: 0px 10px 10px 0px;
    position: absolute;
    z-index: 9;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 4px;
      height: 22px;
      background: #fff;
    }
  }
  &__play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  &__tab {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    &-content {
      padding: 0px 36px 26px;
    }
    &_color {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }
  &__question {
     &-tag {
      min-width: 60px;
      color: #ff5613;
    }
    &-user {
      min-width: 60px;
    }
    &-reply {
      padding-left: 50px;
    }
  }
  &__answers {
    padding: 0 48px;
    margin-top: 16px;
    &-item {
      margin-top: 6px;
    }
    &-to {
      color: #0e53ef;
      margin-right: 10px;
    }
    &-text {
      font-weight: normal;
    }
  }
  &__files {
    margin-bottom: 10px;
    &-text {
      color: #ff5613;
      font-size: 14px;
    }
    &-name {
      color: #333333;
      font-size: 14px;
      transition: all 0.4s;
      &:hover {
        text-decoration: underline;
        color: #376cde;
      }
    }
  }
  &__input {
    margin-top: 20px;
  }
}

.watermark {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 9;
  text-align: center;
  &__item {
    color: #8a8a8a;
    font-size: 18px;
    font-weight: bold;
  }
}

.tools__item-icon {
  margin-left: 10px;
  margin-top: 4px;
}
</style>
