<template>
  <div>
    <div class="task" :class="{'task-pt': title.length === 0}">
      <div class="task__title" v-if="title.length > 0">
        当前目录:
        <span class="task__title_red">{{title}}</span>
      </div>
      <div class="level" v-for="(item, index) in data" :key="index">
        <a-tooltip placement="bottom" :title="item.courseName">
          <div
            class="level__title"
            :class="{'level__title_active': active === index}"
            @click="clickFun(item, index)"
          >
            {{item.courseName}}
            <a-icon
              v-if="item.passFlag === 1"
              type="check"
              style="color:#17D40E"
            />
          </div>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "学习园地"
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    passFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    clickFun(item, index) {
      this.active = index;
      this.$emit("selectCourse", item);
    }
  },
  watch: {
    passFlag: function(val) {
      if (val) {
        let count = 0
        setTimeout(() => {
          this.data.find((item, index) => {
            if (count === 1) this.clickFun(item, index)
            if (this.active === index) count++
          })
        }, 1000);
      }
    }
  }
};
</script>

<style lang = "scss" scoped>
.task {
  width: 243px;
  height: 589px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  &__title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #c4a468;
    box-sizing: border-box;
    padding: 11px 27px;
    position: relative;
    transition: all 0.6s;
    &_red {
      color: #e10101;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 22px;
      background: #c4a468;
      border-radius: 2px;
    }
  }
}
.level {
  padding: 0 10px;
  box-sizing: border-box;
  /* margin-bottom: 18px; */
  &__title {
    line-height: 28px;
    /* background: #EFEFEF; */
    font-size: 14px;
    font-family: Microsoft YaHei;
    /* font-weight: bolder; */
    color: #333333;
    box-sizing: border-box;
    padding: 0 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      color: #376cde;
      text-decoration: underline;
      /* background: #EFEFEF; */
    }
    &_active {
      color: #376cde;
      background: #efefef;
    }
  }
  &__content {
    box-sizing: border-box;
    padding: 0 16px;
  }
  &__item {
    margin-top: 8px;
    cursor: pointer;
    &:hover .level__item-text {
      color: #376cde;
    }
    &-garde {
      color: #c4a468;
      font-size: 14px;
      font-weight: bolder;
    }
  }
}
.task-pt {
  padding-top: 25px;
}
</style>
